"use client";
import { css } from "@/styled-system/css";
import { Box, HStack, Stack } from "@/styled-system/jsx";
import { center } from "@/styled-system/patterns";
import Link from "next/link";
import { NavLink } from "./nav-link";
import { ThemeSwitchIconButton } from "./theme-switch-button";
import SearchBar from "@/components/Navbar/search-bar";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";
import { ActionIcon, Group, Modal, rem } from "@mantine/core";
import { Icon } from "@iconify/react";
import { useDisclosure } from "@mantine/hooks";
import SignInButton from "../Button/SignInButton";
import SignOutButton from "../Button/SignOutButton";
import LogoLetter from "../Icons/LogoLetter";
import Close from "../Icons/Close";
import classes from "../Modal/modal.module.css";

export const MobileNavBar = ({ session }) => {
  const pathname = usePathname();
  const [page, setPage] = useState(undefined);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    const pageFalse = () => {
      setPage(false);
    };
    const pageTrue = () => {
      setPage(true);
    };

    if (pathname === "/disc-search") {
      pageTrue();
    } else {
      pageFalse();
    }
  });
  return (
    <Box
      zIndex={10}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        height: rem(60),
        transition: "transform 400ms ease",
      }}
    >
      <HStack
        height="16"
        py="5"
        px="4"
        position="relative"
        justify="space-between"
        backdropFilter="auto"
        backdropBlur="sm"
        bg={{ base: "lightAlpha", _dark: "darkAlpha" }}
      >
        <Box w="100px">
          <Link
            href="/"
            prefetch={false}
            className={center({ flexShrink: "0" })}
            aria-label="Home Page"
          >
            <LogoLetter />
          </Link>
        </Box>
        {page && <SearchBar />}
        <HStack gap="4">
          {!session ? (
            <SignInButton size={"xs"} />
          ) : (
            <SignOutButton size={"xs"} />
          )}
          <button
            onClick={open}
            type="button"
            aria-label={"Menu Button"}
            className={css({
              px: "1",
              py: "1",
              color: "var(--colors-bg-darktolight)",
              _hover: {
                color: "brand",
              },
            })}
          >
            <Icon icon="ion:menu" width="32" height="32" />
          </button>
          <Modal
            className={classes.modal}
            classNames={{
              content: classes.content,
              header: classes.header,
              body: classes.body,
            }}
            opened={opened}
            onClose={close}
            radius="lg"
            withCloseButton={false}
          >
            <button
              onClick={close}
              type="button"
              aria-label={"Menu Button"}
              className={css({
                position: "absolute",
                top: "12px",
                right: "12px",
                px: "1",
                py: "1",
                textDecoration: "none",
                _hover: {
                  color: "brand",
                },
              })}
            >
              <Close
                className={css({
                  width: "1em",
                  height: "auto",
                  textDecoration: "none",
                  color: "var(--colors-bg-darktolight)",
                })}
                aria-label={"Close Button"}
              />
            </button>
            <Stack align="center" px={"75px"} py={"25px"}>
              <ThemeSwitchIconButton />
              <NavLink href="/disc-search" close={close}>
                Search
              </NavLink>
              <NavLink href="/advance-disc-search" close={close}>
                Advance Search
              </NavLink>
              <NavLink href="/disc-multi-search" close={close}>
                MultiSearch
              </NavLink>
              <NavLink href="/disc-brands" close={close}>
                Brands
              </NavLink>
              {session && (
                <NavLink href="/account" close={close}>
                  Account
                </NavLink>
              )}

              {!session ? <SignInButton /> : <SignOutButton />}

              <Group gap="xs" justify="flex-end" wrap="nowrap" pt={"10px"}>
                <ActionIcon
                  component="a"
                  size="lg"
                  variant="default"
                  radius="xl"
                  href="https://www.facebook.com/people/Stacks-on-Stacks/100089439401540/"
                  aria-label="Facebook Stacks on Stacks"
                >
                  <Icon icon="ri:facebook-fill" />
                </ActionIcon>
                <ActionIcon
                  component="a"
                  size="lg"
                  variant="default"
                  radius="xl"
                  href="https://www.instagram.com/stacksonstacksdg/"
                  aria-label="Instagram Stacks on Stacks"
                >
                  <Icon icon="mdi:instagram" />
                </ActionIcon>
              </Group>
            </Stack>
          </Modal>
        </HStack>
      </HStack>
    </Box>
  );
};
