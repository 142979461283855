import(/* webpackMode: "eager", webpackExports: ["ColorSchemeScript"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["MantineProvider"] */ "/app/node_modules/@mantine/core/esm/core/MantineProvider/MantineProvider.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/core/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Notifications"] */ "/app/node_modules/@mantine/notifications/esm/Notifications.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["cleanNotifications","cleanNotificationsQueue","createNotificationsStore","hideNotification","notifications","notificationsStore","showNotification","updateNotification","updateNotificationsState","useNotifications"] */ "/app/node_modules/@mantine/notifications/esm/notifications.store.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@mantine/notifications/styles.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["mantineTheme"] */ "/app/src/app/mantine-theme.js");
;
import(/* webpackMode: "eager" */ "/app/src/app/query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/app/src/components/Navbar/navbar.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/utils/analytics.tsx");
